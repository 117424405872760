import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Quiz from "reactjs-quiz";

const McqSubmit = ({ type }) => {
	const apiEndpoint = `https://americasolarpros.herokuapp.com/mcq`;
	const [allMcq, setAllMcq] = useState([]);
	useEffect(() => {
		fetch(`${apiEndpoint}/${type}`)
			.then((res) => res.json())
			.then((data) => {
				setAllMcq(data);
			});
	}, [apiEndpoint, type]);

	const quiz = allMcq && {
		quizTitle: `${type?.toUpperCase()} Quiz`,
		quizSynopsis: "",
		questions: allMcq,
	};

	return (
		<Container sx={{ mb: 3.5 }}>
			{allMcq?.length > 0 && (
				<Quiz
					className='textColor'
					quiz={quiz}
					shuffle={true}
					onComplete={(result) => console.log(result)}
				/>
			)}
		</Container>
	);
};

export default McqSubmit;
