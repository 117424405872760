import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, Outlet } from "react-router-dom";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import PortraitIcon from "@mui/icons-material/Portrait";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FeedIcon from "@mui/icons-material/Feed";
import InfoIcon from "@mui/icons-material/Info";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PostAddIcon from "@mui/icons-material/PostAdd";
import StorageIcon from "@mui/icons-material/Storage";
import { Avatar, Badge, Button, CardMedia } from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PeopleIcon from "@mui/icons-material/People";
import ShareIcon from "@mui/icons-material/Share";
import { signOut } from "firebase/auth";
import auth from "../../Firebase/firebase.config";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import ScheduleIcon from "@mui/icons-material/Schedule";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Navigate } from "react-router-dom";
import GavelIcon from "@mui/icons-material/Gavel";
import useDataByEmail from "./useDataByEmail";
import FaceIcon from "@mui/icons-material/Face";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import MailIcon from "@mui/icons-material/Mail";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NewUserNotice from "./NewUserNotice";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import GetAuth from "../../GetAuth";
/* import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle"; */
/* import { useSendEmailVerification } from "react-firebase-hooks/auth"; */
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import io from "socket.io-client";
import Swal from "sweetalert2";
import UnreadChat from "./UnreadChat";
import BarChartIcon from "@mui/icons-material/BarChart";
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { GridCheckCircleIcon } from "@mui/x-data-grid";

const drawerWidth = 275;

const socket = io(`https://americasolarpros.herokuapp.com`);

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === "dark"
			? "rgba(255, 255, 255, .05)"
			: "rgba(0, 0, 0, .03)",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
}));

function Dashboard(props) {
	const [data] = useDataByEmail();
	const [lastVisitDate, setLastVisitDate] = React.useState();
	React.useEffect(() => {
		setLastVisitDate(reactLocalStorage.get("lastVisitDate"));
	}, []);

	React.useEffect(() => {
		const currentDate = new Date().toLocaleDateString();
		if (data && lastVisitDate !== currentDate) {
			axios
				.put(
					`https://americasolarpros.herokuapp.com/lastVisited/${data?.url}`,
					{
						userName: data?.url,
						lastVisited: new Date().toLocaleString("en-US", {
							timeZone: "America/Los_Angeles",
							year: "numeric",
							month: "2-digit",
							day: "2-digit",
							hour: "2-digit",
							minute: "2-digit",
							second: "2-digit",
							timeZoneName: "short",
						}),
					},
				)
				.then(function (response) {
					setLastVisitDate(currentDate);
					reactLocalStorage.set("lastVisitDate", currentDate);
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	}, [data, lastVisitDate]);
	React.useEffect(() => {
		if (data?.url) {
			socket.emit("join_room", data?.url);
		}
	}, [data]);
	function playSound() {
		const audio = new Audio("/beep.mp3");
		audio.play();
	}
	React.useEffect(() => {
		socket.on("alert_rec", (data) => {
			data?.alert === true &&
				Swal.fire({
					icon: "alert",
					title: "New message",
					showConfirmButton: false,
					timer: 1500,
				});
			playSound();
		});
	}, []);

	const location = useLocation();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const [expanded, setExpanded] = React.useState("");

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	/* 	const [version, setVersion] = React.useState();
	React.useEffect(() => {
		axios.get(`https://americasolarpros.herokuapp.com/version`).then((res) => {
			setVersion(res.data);
		});
	}, []); */

	const drawer = (
		<Box className='dashboard'>
			{/* {data?.role === "Admin" && (
				<input
					style={{ width: "45px", borderColor: "white" }}
					type='number'
					defaultValue={version?.version}
					onBlur={(e) =>
						axios
							.put(`https://americasolarpros.herokuapp.com/version`, {
								version: e.target.value,
							})
							.then(function (response) {
								Swal.fire({
									icon: "success",
									title: "Version Updated",
									showConfirmButton: false,
									timer: 1500,
								});
							})
							.catch(function (error) {
								console.log(error);
							})
					}
				/>
			)} */}
			<Typography
				sx={{
					py: 1.5,
					color: "white",
					width: "100%",
					fontWeight: "bold",
					fontSize: "22px !important",
				}}>
				America Solar
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					py: 1,
				}}>
				<Avatar
					sx={{ border: "2px solid white", mb: 0.7 }}
					alt=''
					src={data?.profile?.imageLink2}
				/>
				<Typography sx={{ color: "white", fontSize: "17px !important" }}>
					{data?.headline?.title}
				</Typography>
				<Typography sx={{ color: "white" }}>{data?.role}</Typography>
			</Box>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to=''>
				<ListItem
					button
					className={location?.pathname === "/dashboard" && "activeButton"}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<FaceIcon />
					</ListItemIcon>
					<ListItemText primary={"My Profile"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='Planner'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/Planner" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<EventNoteIcon />
					</ListItemIcon>
					<ListItemText primary={"Planner"} />
				</ListItem>
			</Link>
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='Charts'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/Charts" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<BarChartIcon />
						</ListItemIcon>
						<ListItemText primary={"Charts"} />
					</ListItem>
				</Link>
			)}
			{data?.Canvass === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='Canvass'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/Canvass" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<CastForEducationIcon />
						</ListItemIcon>
						<ListItemText primary={"Canvass"} />
					</ListItem>
				</Link>
			)}
			{data?.RPS === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='RPS'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/RPS" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<CastForEducationIcon />
						</ListItemIcon>
						<ListItemText primary={"Solar RPS"} />
					</ListItem>
				</Link>
			)}
			{data?.Tutorials === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='tutorials'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/tutorials" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<CastForEducationIcon />
						</ListItemIcon>
						<ListItemText primary={"Turorials"} />
					</ListItem>
				</Link>
			)}
			{
				/* parseInt(data?.level?.split("-")?.[1]) >= 1 && */
				data?.L1Marketing === "Yes" && (
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='Level1Programs'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/Level1Programs" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon />
							</ListItemIcon>
							<ListItemText primary={"L1:Marketing"} />
						</ListItem>
					</Link>
				)
			}
			{
				/* parseInt(data?.level?.split("-")?.[1]) >= 2 && */
				data?.L2Presentation === "Yes" && (
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='Level2Programs'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/Level2Programs" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon />
							</ListItemIcon>
							<ListItemText primary={"L2:Presentation"} />
						</ListItem>
					</Link>
				)
			}
			{
				/* parseInt(data?.level?.split("-")?.[1]) >= 3 &&  */
				data?.L3Design === "Yes" && (
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='Level3Programs'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/Level3Programs" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon />
							</ListItemIcon>
							<ListItemText primary={"L3:Design"} />
						</ListItem>
					</Link>
				)
			}
			{data?.QuickStart === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='trainings'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/trainings" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Quick Start"} />
					</ListItem>
				</Link>
			)}
			{data?.ADTRPS === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='ADTPrograms'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/ADTPrograms" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"HD Floor Training"} />
					</ListItem>
				</Link>
			)}
			{data?.GSW === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='GoalSettingWorkshops'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/GoalSettingWorkshops" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Goal Setting Workshop"} />
					</ListItem>
				</Link>
			)}

			{data?.FundingPrep === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='FundingPreps'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/FundingPreps" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Water Sales Mastery"} />
					</ListItem>
				</Link>
			)}
			{data?.podcast === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='Podcasts'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/Podcasts" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Podcast"} />
					</ListItem>
				</Link>
			)}
			{data?.L3Calculator === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='L3Calculators'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/L3Calculators" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Other Options"} />
					</ListItem>
				</Link>
			)}
			{data?.L2Calculator === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='L2Calculators'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/L2Calculators" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"L2 Calculator"} />
					</ListItem>
				</Link>
			)}
			{data?.FundingBoard === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='FundingBoards'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/FundingBoards" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Funding Boards"} />
					</ListItem>
				</Link>
			)}
			{data?.TranzactCard === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='TranzactCards'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/TranzactCards" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Sunnova Trainings"} />
					</ListItem>
				</Link>
			)}
			{data?.Sunova === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='Sunovas'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/Sunovas" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Closers Training"} />
					</ListItem>
				</Link>
			)}
			{data?.SpinD === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='SpinDoctorings'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/SpinDoctorings" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Spin Doctoring"} />
					</ListItem>
				</Link>
			)}
			{data?.TMOSV === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='TheMasterOfSuccessVideoss'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/TheMasterOfSuccessVideoss" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"The Master Of Success Videos"} />
					</ListItem>
				</Link>
			)}
			{data?.TMOSP === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='TheMasterSuccessPlanners'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/TheMasterSuccessPlanners" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"The Master Success Planner"} />
					</ListItem>
				</Link>
			)}
			{data?.WP === "Yes" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='WinningPresentationss'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/WinningPresentationss" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Winning Presentations"} />
					</ListItem>
				</Link>
			)}
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allposts'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allposts" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<PostAddIcon />
					</ListItemIcon>
					<ListItemText primary={"Social Media Posts"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='viewnews'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/viewnews" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<AnnouncementIcon />
					</ListItemIcon>
					<ListItemText primary={"News"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='MemoryJoggerList'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/MemoryJoggerList" &&
						"activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<StorageIcon />
					</ListItemIcon>
					<ListItemText primary={"1st 10 Training List"} />
				</ListItem>
			</Link>
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='RequestTrainingCallList'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/RequestTrainingCallList" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"All Training Call Requests"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='TeamManage'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/TeamManage" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Team Manage"} />
					</ListItem>
				</Link>
			)}
			{(data?.role === "Admin" ||
				data?.role === "Regional" ||
				data?.role === "Manager" ||
				data?.role === "Team-Lead" ||
				data?.role === "Trainer") && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='TeamInfo'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/TeamInfo" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ModelTrainingIcon />
						</ListItemIcon>
						<ListItemText primary={"Team View"} />
					</ListItem>
				</Link>
			)}
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='scheduleByAgent'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/scheduleByAgent" &&
						"activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<DateRangeIcon />
					</ListItemIcon>
					<ListItemText primary={"Add Closed Deal"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='bookAppoinment'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/bookAppoinment" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<DateRangeIcon />
					</ListItemIcon>
					<ListItemText primary={"Generate New Lead"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='calander'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/calander" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<CalendarTodayIcon />
					</ListItemIcon>
					<ListItemText primary={"Calendar"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='addpaymentdetails'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/addpaymentdetails" &&
						"activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<AttachMoneyIcon />
					</ListItemIcon>
					<ListItemText primary={"Manage Payment Details"} />
				</ListItem>
			</Link>
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}>
				<AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
					<ListItemIcon className='listItemIcon'>
						<CardMembershipIcon />
					</ListItemIcon>
					<Typography>Card Setting</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List>
						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='bannerphoto'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/bannerphoto" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<PhotoCameraBackIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Banner Photo"} />
								</ListItem>
							</Link>
						)}
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='profilephoto'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/profilephoto" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<PortraitIcon />
								</ListItemIcon>
								<ListItemText primary={"Profile Photo"} />
							</ListItem>
						</Link>

						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='galleryphoto'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/galleryphoto" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<ViewCarouselIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Gallery Photos"} />
								</ListItem>
							</Link>
						)}
						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='managevideo'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/managevideo" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<OndemandVideoIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Gallery Videos"} />
								</ListItem>
							</Link>
						)}
						{data?.role === "Admin" && (
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='colorchange'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/colorchange" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<ColorLensIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Color & Border"} />
								</ListItem>
							</Link>
						)}
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='headline'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/headline" && "activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<FeedIcon />
								</ListItemIcon>
								<ListItemText primary={"Headline"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='buttonslink'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/buttonslink" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<PostAddIcon />
								</ListItemIcon>
								<ListItemText primary={"Social Media Profiles"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='details'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/details" && "activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<InfoIcon />
								</ListItemIcon>
								<ListItemText primary={"Bio"} />
							</ListItem>
						</Link>
					</List>
				</AccordionDetails>
			</Accordion>
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='ScheduleOrJoinDetails'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/ScheduleOrJoinDetails" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<FeedIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Schedule And Join Details"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='inviteuser'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/inviteuser" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<ShareIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Share Message Manage"} />
					</ListItem>
				</Link>
			)}
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allreviews'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allreviews" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ReviewsIcon />
					</ListItemIcon>
					<ListItemText primary={"All Reviews"} />
				</ListItem>
			</Link>
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='allemails'>
				<ListItem
					button
					className={
						location?.pathname === "/dashboard/allemails" && "activeButton"
					}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<ContactMailIcon />
					</ListItemIcon>
					<ListItemText primary={"All Mails"} />
				</ListItem>
			</Link>
			{data?.role === "Admin" && (
				<>
					{" "}
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='AgentsPayments'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/AgentsPayments" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PeopleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Funded Users"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allusers'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/allusers" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PeopleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Users"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='BlockedScheduleDate'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/BlockedScheduleDate" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<EventBusyIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Manage Schedule Date & Time"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='meetings'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/meetings" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ScheduleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Scheduled Meetigs"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='meetingSucceed'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/meetingSucceed" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ScheduleIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Succeed Meetings"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='SuccessfullSales'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/SuccessfullSales" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<GridCheckCircleIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Successfull Sales"} />
					</ListItem>
				</Link>
			)}
			<Accordion
				expanded={expanded === "panel2"}
				onChange={handleChange("panel2")}>
				<AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
					<ListItemIcon className='listItemIcon'>
						<ScheduleIcon />
					</ListItemIcon>
					<Typography>Opportunities</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='invitedusers'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/invitedusers" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"All Opportunities"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='DesignStarted'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/DesignStarted" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Design Started"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='AlarmDeal'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/AlarmDeal" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Water Deal"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='M2Funded'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/M2Funded" && "activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Funded"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='InstallScheduled'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/InstallScheduled" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Install Scheduled"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='InPermitting'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/InPermitting" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"In Permitting"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='PhoneRoomFollowup'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/PhoneRoomFollowup" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Phone Room Followup"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='ManagerFollowup'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/ManagerFollowup" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Rehash"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='Canceled'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/Canceled" && "activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Canceled"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='PostSiteSurvey'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/PostSiteSurvey" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Post site survey"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='AgentFollowup'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/AgentFollowup" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Agent Follow Up"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='PreSiteSurvey'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/PreSiteSurvey" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"Sold"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='DNQ'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/DNQ" && "activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"DNQ"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='InProcess'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/InProcess" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<ScheduleIcon />
								</ListItemIcon>
								<ListItemText primary={"On Hold"} />
							</ListItem>
						</Link>
						<Link
							style={{
								textDecoration: "none",
								fontWeight: "bold",
								fontSize: "15px",
							}}
							to='successfullinvites'>
							<ListItem
								button
								className={
									location?.pathname === "/dashboard/successfullinvites" &&
									"activeButton"
								}>
								<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
									<WorkOffIcon />
								</ListItemIcon>
								<ListItemText primary={"Project Completed"} />
							</ListItem>
						</Link>
					</List>
				</AccordionDetails>
			</Accordion>
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allReferrels'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/allReferrels" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<LiveHelpIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Referrels"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='RefferelMessgae'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/RefferelMessgae" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<LiveHelpIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Referrel Messgae"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='managefaq'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/managefaq" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<LiveHelpIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Manage FAQ"} />
					</ListItem>
				</Link>
			)}
			<Link
				style={{
					textDecoration: "none",
					fontWeight: "bold",
					fontSize: "15px",
				}}
				to='faq'>
				<ListItem
					button
					className={location?.pathname === "/dashboard/faq" && "activeButton"}>
					<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
						<LiveHelpIcon />
					</ListItemIcon>
					<ListItemText primary={"FAQ"} />
				</ListItem>
			</Link>
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='manageproducts'>
					<ListItem
						button
						className={
							location?.pathname.includes("/dashboard/manageproducts") &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<LocalGroceryStoreIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Manage Products And Ques"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='newuserwelcome'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/newuserwelcome" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<PeopleIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"New User Welcome Popup"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='manageterms'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/manageterms" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<GavelIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Manage Terms"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='newsletter'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/newsletter" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Send Newsletter"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allnewsletter'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/allnewsletter" &&
							"activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"All Send Newsletter"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Accordion
					expanded={expanded === "panel3"}
					onChange={handleChange("panel3")}>
					<AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
						<ListItemIcon className='listItemIcon'>
							<ScheduleIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<Typography>Level Contents</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<List>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='allLevel1Program'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/allLevel1Program" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Level 1 Contents"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='addLevel1Program'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/addLevel1Program" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Add Level 1 Contents"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='allLevel1MCQ'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/allLevel1MCQ" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Level 1 MCQ"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='addLevel1MCQ'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/addLevel1MCQ" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Add Level 1 MCQ"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='allLevel2Program'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/allLevel2Program" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Level 2 Contents"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='addLevel2Program'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/addLevel2Program" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Add Level 2 Contents"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='allLevel2MCQ'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/allLevel2MCQ" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Level 2 MCQ"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='addLevel2MCQ'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/addLevel2MCQ" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Add Level 2 MCQ"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='allLevel3Program'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/allLevel3Program" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Level 3 Contents"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='addLevel3Program'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/addLevel3Program" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Add Level 3 Contents"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='allLevel3MCQ'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/allLevel3MCQ" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Level 3 MCQ"} />
								</ListItem>
							</Link>
							<Link
								style={{
									textDecoration: "none",
									fontWeight: "bold",
									fontSize: "15px",
								}}
								to='addLevel3MCQ'>
								<ListItem
									button
									className={
										location?.pathname === "/dashboard/addLevel3MCQ" &&
										"activeButton"
									}>
									<ListItemIcon
										sx={{ justifyContent: "center", color: "white" }}>
										<NewspaperIcon sx={{ color: "red !important" }} />
									</ListItemIcon>
									<ListItemText primary={"Add Level 3 MCQ"} />
								</ListItem>
							</Link>
						</List>
					</AccordionDetails>
				</Accordion>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='newuseremail'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/newuseremail" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<NewspaperIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"New User Email Drip"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='publishnews'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/publishnews" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<AnnouncementIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"Publish News"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<Link
					style={{
						textDecoration: "none",
						fontWeight: "bold",
						fontSize: "15px",
					}}
					to='allnews'>
					<ListItem
						button
						className={
							location?.pathname === "/dashboard/allnews" && "activeButton"
						}>
						<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
							<AnnouncementIcon sx={{ color: "red !important" }} />
						</ListItemIcon>
						<ListItemText primary={"All News"} />
					</ListItem>
				</Link>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='alltutorial'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/alltutorial") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Tutorials"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addtutorial'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addtutorial" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Tutorials"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allRPS'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allRPS") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Solar RPS"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addRPS'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addRPS" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Solar RPS"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allCanvass'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allCanvass") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Canvass"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addCanvass'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addCanvass" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<CastForEducationIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Canvass"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='alltraining'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/alltraining") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Trainings"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addtraining'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addtraining" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Trainings"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allGoalSettingWorkshop'>
						<ListItem
							button
							className={
								location?.pathname.includes(
									"/dashboard/allGoalSettingWorkshop",
								) && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Goal Setting Workshop"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addGoalSettingWorkshop'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addGoalSettingWorkshop" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Goal Setting Workshop"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allPodcast'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allPodcast") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Podcast"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addPodcast'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addPodcast" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Podcast"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allL3Calculator'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allL3Calculator") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Other Options"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addL3Calculator'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addL3Calculator" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Other Options"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allL2Calculator'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allL2Calculator") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All L2 Calculator"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addL2Calculator'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addL2Calculator" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add L2 Calculator"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allFundingBoard'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allFundingBoard") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All FundingBoard"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addFundingBoard'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addFundingBoard" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add FundingBoard"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allTranzactCard'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allTranzactCard") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Sunnova Training"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addTranzactCard'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addTranzactCard" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Sunnova Training"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allFundingPrep'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allFundingPrep") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Water Sales Mastery"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addFundingPrep'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addFundingPrep" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Water Sales Mastery"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allSunova'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allSunova") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Closers Training"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addSunova'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addSunova" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Closers Training"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allSpinDoctoring'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allSpinDoctoring") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Spin Doctoring"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addSpinDoctoring'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addSpinDoctoring" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Spin Doctoring"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allTheMasterOfSuccessVideos'>
						<ListItem
							button
							className={
								location?.pathname.includes(
									"/dashboard/allTheMasterOfSuccessVideos",
								) && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All The Master Of Success Videos"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addTheMasterOfSuccessVideos'>
						<ListItem
							button
							className={
								location?.pathname ===
									"/dashboard/addTheMasterOfSuccessVideos" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add The Master Of Success Videos"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allTheMasterSuccessPlanner'>
						<ListItem
							button
							className={
								location?.pathname.includes(
									"/dashboard/allTheMasterSuccessPlanner",
								) && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All The Master Success Planner"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addTheMasterSuccessPlanner'>
						<ListItem
							button
							className={
								location?.pathname ===
									"/dashboard/addTheMasterSuccessPlanner" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add The Master Success Planner"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allWinningPresentations'>
						<ListItem
							button
							className={
								location?.pathname.includes(
									"/dashboard/allWinningPresentations",
								) && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Winning Presentations"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addWinningPresentations'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addWinningPresentations" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Winning Presentations"} />
						</ListItem>
					</Link>
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allADTProgram'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allADTProgram") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All HD Floor Training"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addADTProgram'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addADTProgram" &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add HD Floor Training"} />
						</ListItem>
					</Link>
					{/* 	<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allADTMCQ'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/allADTMCQ" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All ADT MCQ"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addADTMCQ'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addADTMCQ" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<ModelTrainingIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add ADT MCQ"} />
						</ListItem>
					</Link> */}
				</>
			)}
			{data?.role === "Admin" && (
				<>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='allpost'>
						<ListItem
							button
							className={
								location?.pathname.includes("/dashboard/allpost") &&
								"activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PostAddIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"All Social Media Posts"} />
						</ListItem>
					</Link>
					<Link
						style={{
							textDecoration: "none",
							fontWeight: "bold",
							fontSize: "15px",
						}}
						to='addpost'>
						<ListItem
							button
							className={
								location?.pathname === "/dashboard/addpost" && "activeButton"
							}>
							<ListItemIcon sx={{ justifyContent: "center", color: "white" }}>
								<PostAddIcon sx={{ color: "red !important" }} />
							</ListItemIcon>
							<ListItemText primary={"Add Social Media Posts"} />
						</ListItem>
					</Link>
				</>
			)}
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;
	const [user, loading] = GetAuth();

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [open, setOpen] = React.useState(!user?.emailVerified === true);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [openNotice, setOpenNotice] = React.useState();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [unRead, setunread] = React.useState([]);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [openChat, setOpenChat] = React.useState(false);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/chats`)
			.then((res) => res.json())
			.then((data) => {
				setunread(
					data?.filter((e) =>
						e?.chats?.find((n) => !n?.adminSeenTime && n?.adminSeen === false),
					),
				);
			});
	}, [user?.url, open, location, openChat]);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useEffect(() => {
		setOpenNotice(data && data.checkedNotification === false);
	}, [data]);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	/* const [sendEmailVerification, sending] = useSendEmailVerification(auth); */

	if (!loading && user && data && user?.email !== data?.email) {
		return <Navigate to={"/login"} state={{ from: location }} replace />;
	}
	if (loading || !user || !data) {
		return (
			<div
				style={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<CardMedia
					image='/loading.png'
					alt='loading logo'
					className='loading-logo'
					sx={{
						width: 170,
						height: 170,
					}}
				/>
			</div>
		);
	}
	if (data && data?.status === "Inactive") {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					minHeight: "100vh",
				}}>
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h3'
					component='div'>
					OPPS!
				</Typography>
				<br />
				<Typography
					className='textColor'
					sx={{ fontWeight: "bold" }}
					variant='h5'
					component='div'>
					Contact Admin to get access of the card
				</Typography>
			</div>
		);
	}

	return (
		<Box sx={{ display: "flex" }}>
			<>
				<CssBaseline />
				<AppBar
					position='fixed'
					className='bgColor'
					sx={{
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						padding: 0,
						ml: { sm: `${drawerWidth}px` },
					}}>
					<Toolbar>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawerToggle}
							sx={{ mr: 1, display: { sm: "none" } }}>
							<MenuIcon />
						</IconButton>
						<Box
							display='flex'
							sx={{ flexGrow: 1, alignItems: "center", color: "white" }}>
							<Typography variant='body'>REMOTE BIZ CENTER</Typography>
						</Box>
						{data?.role === "Admin" && (
							<Badge
								onClick={() => setOpenChat(true)}
								badgeContent={unRead?.length || "0"}
								color='error'
								sx={{ mr: 3, cursor: "pointer", color: "white" }}>
								<MailIcon />
							</Badge>
						)}
						<Button
							onClick={() => signOut(auth)}
							color='inherit'
							className='logout'>
							LogOut
						</Button>
					</Toolbar>
				</AppBar>
				<Box
					/* onClick={() => setMobileOpen(false)} */
					component='nav'
					sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
					aria-label='mailbox folders'>
					<Drawer
						container={container}
						variant='temporary'
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true,
						}}
						className='dashboard'
						sx={{
							display: { xs: "block", sm: "none" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}>
						{drawer}
					</Drawer>
					<Drawer
						variant='permanent'
						className='dashboard'
						sx={{
							display: { xs: "none", sm: "block" },
							"& .MuiDrawer-paper": {
								boxSizing: "border-box",
								width: drawerWidth,
							},
							backgroundColor: "transparent",
						}}
						open>
						{drawer}
					</Drawer>
				</Box>
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
					}}>
					<Toolbar />
					{openNotice && (
						<NewUserNotice
							openNotice={openNotice}
							data={data}
							setOpenNotice={setOpenNotice}
							setOpen={setOpen}
						/>
					)}
					{/* {open && (
						<Alert
							sx={{
								width: "max-content",
								textAlign: "left",
								position: "fixed",
								top: " 15%",
								right: "50%",
								transform: "translate(50%,-15%)",
								zIndex: 9,
							}}
							action={
								<>
									<button
										style={{
											backgroundColor: "#F27471",
											padding: "7px",
											color: "white",
											borderRadius: "5px",
										}}
										size='small'
										onClick={() => sendEmailVerification()}>
										Resend
									</button>
									<button
										style={{
											backgroundColor: "#F27471",
											padding: "7px",
											color: "white",
											borderRadius: "5px",
										}}
										size='small'
										onClick={() => {
											setOpen(false);
										}}>
										X
									</button>
								</>
							}
							severity='error'>
							<AlertTitle>Warning</AlertTitle>
							<strong>Please verify your email</strong> — check inbox/spam
							folder!
						</Alert>
					)} */}
					{/* 	<Backdrop
						sx={{
							color: "#fff",
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open={sending}>
						<CircularProgress color='inherit' />
					</Backdrop> */}
					<Outlet></Outlet>
				</Box>
			</>
			{openChat && (
				<UnreadChat open={openChat} setOpen={setOpenChat} unRead={unRead} />
			)}
		</Box>
	);
}

Dashboard.propTypes = {
	window: PropTypes.func,
};

export default Dashboard;
