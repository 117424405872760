import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AllMails from "./Pages/Dashboard/AllMails/AllMails";
import AllReviews from "./Pages/Dashboard/AllReviews/AllReviews";
import BannerPhoto from "./Pages/Dashboard/BannerPhoto/BannerPhoto";
import ButtonsLink from "./Pages/Dashboard/ButtonsLink/ButtonsLink";
import ColorChange from "./Pages/Dashboard/ColorChange/ColorChange";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Details from "./Pages/Dashboard/Details/Details";
import Galleryphoto from "./Pages/Dashboard/Galleryphoto/Galleryphoto";
import Headline from "./Pages/Dashboard/Headline/Headline";
import ProfilePhoto from "./Pages/Dashboard/ProfilePhoto/ProfilePhoto";
import HomepageMain from "./Pages/Homepage/HomepageMain";
import Login from "./Pages/Login/Login";
import useData from "./useData";
import AllUsers from "./Pages/Dashboard/AdminSection/AllUsers/AllUsers";
import NotFound from "./Pages/NotFound/NotFound";
import Schedule from "./Pages/Schedule/Schedule";
import InviteUser from "./Pages/Dashboard/AdminSection/InviteUser/InviteUser";
import InvitedUser from "./Pages/Dashboard/AdminSection/InvitedUser/InvitedUser";
import RequireAuth from "./RequireAuth/RequireAuth";
import { CardMedia, Typography } from "@mui/material";
import TeamInvite from "./Pages/TeamInvite/TeamInvite";
import ManageQuestions from "./Pages/Dashboard/AdminSection/ManageQuestions/ManageQuestions";
import ManageProducts from "./Pages/Dashboard/AdminSection/ManageProducts/ManageProducts";
import NewsLetter from "./Pages/Dashboard/AdminSection/NewsLetter/NewsLetter";
import ManageFAQ from "./Pages/Dashboard/AdminSection/ManageFAQ/ManageFAQ";
import FAQ from "./Pages/FAQ/FAQ";
import SuccessInvite from "./Pages/Dashboard/AdminSection/InvitedUser/SuccessInvite";
import AddVideo from "./Pages/Dashboard/AddVideo/AddVideo";
import AllSentNewsletter from "./Pages/Dashboard/AdminSection/AllSentNewsletter/AllSentNewsletter";
import TermManage from "./Pages/Dashboard/AdminSection/TermManage/TermManage";
import MyProfile from "./Pages/Dashboard/AdminSection/AllUsers/MyProfile";
import PublishNews from "./Pages/Dashboard/AdminSection/PublishNews/PublishNews";
import ViewNews from "./Pages/Dashboard/ViewNews/ViewNews";
import ViewTutorial from "./Pages/Dashboard/ViewTutorial/ViewTutorial";
import NewUserWelcome from "./Pages/Dashboard/AdminSection/NewUserWelcome/NewUserWelcome";
import RequireAuthAdmin from "./RequireAuth/RequireAuthAdmin";
import Calander from "./Pages/Dashboard/AdminSection/Calander/Calander";
import { APIContextProvider } from "./apiContext";
import PublishTraining from "./Pages/Dashboard/AdminSection/PublishTraining/PublishTraining";
import ViewTraining from "./Pages/Dashboard/ViewTraining/ViewTraining";
import ScheduleBook from "./Pages/Dashboard/ScheduleBook/ScheduleBook";
import NewUserEmail from "./Pages/Dashboard/NewUserEmail/NewUserEmail";
import BlockedScheduleDate from "./Pages/Dashboard/AdminSection/BlockedScheduleDate/BlockedScheduleDate";
import SocialMediaPost from "./Pages/Dashboard/AdminSection/SocialMediaPost/SocialMediaPost";
import Meeting from "./Pages/Meeting/Meeting";
import AllMeetings from "./Pages/Dashboard/AdminSection/AllMeetings/AllMeetings";
import SuccessMeeting from "./Pages/Dashboard/AdminSection/AllMeetings/SuccessMeeting";
import ScheduleDashboard from "./Pages/Schedule/ScheduleDashboard";
import AgentsPayments from "./Pages/Dashboard/AdminSection/AllUsers/AgentsPayments";
import MemoryJoggerList from "./Pages/Dashboard/MemoryJoggerList/MemoryJoggerList";
import Test from "./Test";
import DesignStarted from "./Pages/Dashboard/AdminSection/InvitedUser/DesignStarted";
import PhoneRoomFollowup from "./Pages/Dashboard/AdminSection/InvitedUser/PhoneRoomFollowup";
import ManagerFollowup from "./Pages/Dashboard/AdminSection/InvitedUser/ManagerFollowup";
import Canceled from "./Pages/Dashboard/AdminSection/InvitedUser/Canceled";
import PostSiteSurvey from "./Pages/Dashboard/AdminSection/InvitedUser/PostSiteSurvey";
import AgentFollowup from "./Pages/Dashboard/AdminSection/InvitedUser/AgentFollowup";
import DNQ from "./Pages/Dashboard/AdminSection/InvitedUser/DNQ";
import InProcess from "./Pages/Dashboard/AdminSection/InvitedUser/InProcess";
import RequestTrainingCall from "./Pages/Dashboard/RequestTrainingCall/RequestTrainingCall";
import RequestTrainingCallList from "./Pages/Dashboard/RequestTrainingCall/RequestTrainingCallList";
import Bg from "./Pages/Dashboard/AdminSection/Bg/Bg";
import AddPaymentDetails from "./Pages/Dashboard/AddPaymentDetails/AddPaymentDetails";
import AllNews from "./Pages/Dashboard/AdminSection/PublishNews/AllNews";
import UpdateNews from "./Pages/Dashboard/AdminSection/PublishNews/UpdateNews";
import PublishTutorial from "./Pages/Dashboard/AdminSection/PublishTutorial/PublishTutorial";
import AllTutorial from "./Pages/Dashboard/AdminSection/PublishTutorial/AllTutorial";
import UpdateTutorial from "./Pages/Dashboard/AdminSection/PublishTutorial/UpdateTutorial";
import AllTraining from "./Pages/Dashboard/AdminSection/PublishTraining/AllTraining";
import UpdateTraining from "./Pages/Dashboard/AdminSection/PublishTraining/UpdateTraining";
import PublishADTProgram from "./Pages/Dashboard/AdminSection/PublishADTProgram/PublishADTProgram";
import AllADTProgram from "./Pages/Dashboard/AdminSection/PublishADTProgram/AllADTProgram";
import ViewADTProgram from "./Pages/Dashboard/ADTProgram/ViewADTProgram";
import UpdateADTProgram from "./Pages/Dashboard/AdminSection/PublishADTProgram/UpdateADTProgram";
import AllSocialMediaPost from "./Pages/Dashboard/AdminSection/SocialMediaPost/AllSocialMediaPost";
import ViewSocialMediaPost from "./Pages/Dashboard/ViewSocialMediaPost/ViewSocialMediaPost";
import UpdateSocialMediaPost from "./Pages/Dashboard/AdminSection/SocialMediaPost/UpdateSocialMediaPost";
import ScheduleOrJoinDetails from "./Pages/Dashboard/AdminSection/ScheduleOrJoinDetails/ScheduleOrJoinDetails";
import PublishLevel1 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level1/PublishLevel1";
import AllLevel1 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level1/AllLevel1";
import ViewLevel1 from "./Pages/Dashboard/ViewContentsByLevel/ViewLevel1";
import UpdateLevel1 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level1/UpdateLevel1";
import PublishLevel2 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level2/PublishLevel2";
import AllLevel2 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level2/AllLevel2";
import ViewLevel2 from "./Pages/Dashboard/ViewContentsByLevel/ViewLevel2";
import UpdateLevel2 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level2/UpdateLevel2";
import PublishLevel3 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level3/PublishLevel3";
import AllLevel3 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level3/AllLevel3";
import ViewLevel3 from "./Pages/Dashboard/ViewContentsByLevel/ViewLevel3";
import UpdateLevel3 from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level3/UpdateLevel3";
import RefferelsList from "./Pages/Dashboard/Refferels/RefferelsList";
import RefferelMessgae from "./Pages/Dashboard/Refferels/RefferelMessgae";
import TeamInfo from "./Pages/Dashboard/AdminSection/TeamManage/TeamInfo";
import TeamManage from "./Pages/Dashboard/AdminSection/TeamManage/TeamManage";
import FilteredOpp from "./Pages/Dashboard/AdminSection/TeamManage/FilteredOpp";
import PublishCanvass from "./Pages/Dashboard/AdminSection/PublishCanvass/PublishCanvass";
import AllCanvass from "./Pages/Dashboard/AdminSection/PublishCanvass/AllCanvass";
import ViewCanvass from "./Pages/Dashboard/ViewCanvass/ViewCanvass";
import UpdateCanvass from "./Pages/Dashboard/AdminSection/PublishCanvass/UpdateCanvass";
import PublishRPS from "./Pages/Dashboard/AdminSection/PublishRPS/PublishRPS";
import AllRPS from "./Pages/Dashboard/AdminSection/PublishRPS/AllRPS";
import ViewRPS from "./Pages/Dashboard/ViewRPS/ViewRPS";
import UpdateRPS from "./Pages/Dashboard/AdminSection/PublishRPS/UpdateRPS";
import PublishLevel2MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level2MCQ/PublishLevel2MCQ";
import AllLevel2MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level2MCQ/AllLevel2MCQ";
import UpdateLevel2MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level2MCQ/UpdateLevel2MCQ";
import UpdateLevel3MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level3MCQ/UpdateLevel3MCQ";
import AllLevel3MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level3MCQ/AllLevel3MCQ";
import PublishLevel3MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level3MCQ/PublishLevel3MCQ";
import AllLevel1MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level1MCQ/AllLevel1MCQ";
import PublishLevel1MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level1MCQ/PublishLevel1MCQ";
import UpdateLevel1MCQ from "./Pages/Dashboard/AdminSection/ContentsByLevel/Level1MCQ/UpdateLevel1MCQ";
import PublishAdtMcq from "./Pages/Dashboard/AdminSection/PublishADTProgram/AdtMcq/PublishAdtMcq";
import AllAdtMcq from "./Pages/Dashboard/AdminSection/PublishADTProgram/AdtMcq/AllAdtMcq";
import UpdateAdtMcq from "./Pages/Dashboard/AdminSection/PublishADTProgram/AdtMcq/UpdateAdtMcq";
import PublishGoalSettingWorkshop from "./Pages/Dashboard/AdminSection/GoalSettingWorkshop/PublishGoalSettingWorkshop";
import AllGoalSettingWorkshop from "./Pages/Dashboard/AdminSection/GoalSettingWorkshop/AllGoalSettingWorkshop";
import ViewGoalSettingWorkshop from "./Pages/Dashboard/GoalSettingWorkshop/ViewGoalSettingWorkshop";
import UpdateGoalSettingWorkshop from "./Pages/Dashboard/AdminSection/GoalSettingWorkshop/UpdateGoalSettingWorkshop";
import PublishPodcast from "./Pages/Dashboard/AdminSection/Podcast/PublishPodcast";
import AllPodcast from "./Pages/Dashboard/AdminSection/Podcast/AllPodcast";
import ViewPodcast from "./Pages/Dashboard/Podcast/ViewPodcast";
import UpdatePodcast from "./Pages/Dashboard/AdminSection/Podcast/UpdatePodcast";
import PublishSpinDoctoring from "./Pages/Dashboard/AdminSection/SpinDoctoring/PublishSpinDoctoring";
import AllSpinDoctoring from "./Pages/Dashboard/AdminSection/SpinDoctoring/AllSpinDoctoring";
import ViewSpinDoctoring from "./Pages/Dashboard/SpinDoctoring/ViewSpinDoctoring";
import UpdateSpinDoctoring from "./Pages/Dashboard/AdminSection/SpinDoctoring/UpdateSpinDoctoring";
import PublishTheMasterOfSuccessVideos from "./Pages/Dashboard/AdminSection/TheMasterOfSuccessVideos/PublishTheMasterOfSuccessVideos";
import AllTheMasterOfSuccessVideos from "./Pages/Dashboard/AdminSection/TheMasterOfSuccessVideos/AllTheMasterOfSuccessVideos";
import ViewTheMasterOfSuccessVideos from "./Pages/Dashboard/TheMasterOfSuccessVideos/ViewTheMasterOfSuccessVideos";
import UpdateTheMasterOfSuccessVideos from "./Pages/Dashboard/AdminSection/TheMasterOfSuccessVideos/UpdateTheMasterOfSuccessVideos";
import PublishTheMasterSuccessPlanner from "./Pages/Dashboard/AdminSection/TheMasterSuccessPlanner/PublishTheMasterSuccessPlanner";
import AllTheMasterSuccessPlanner from "./Pages/Dashboard/AdminSection/TheMasterSuccessPlanner/AllTheMasterSuccessPlanner";
import ViewTheMasterSuccessPlanner from "./Pages/Dashboard/TheMasterSuccessPlanner/ViewTheMasterSuccessPlanner";
import UpdateTheMasterSuccessPlanner from "./Pages/Dashboard/AdminSection/TheMasterSuccessPlanner/UpdateTheMasterSuccessPlanner";
import PublishWinningPresentations from "./Pages/Dashboard/AdminSection/WinningPresentations/PublishWinningPresentations";
import AllWinningPresentations from "./Pages/Dashboard/AdminSection/WinningPresentations/AllWinningPresentations";
import ViewWinningPresentations from "./Pages/Dashboard/WinningPresentations/ViewWinningPresentations";
import UpdateWinningPresentations from "./Pages/Dashboard/AdminSection/WinningPresentations/UpdateWinningPresentations";
import Charts from "./Pages/Dashboard/Charts/Charts";
import PreSiteSurvey from "./Pages/Dashboard/AdminSection/InvitedUser/PreSiteSurvey";
import AlarmDeal from "./Pages/Dashboard/AdminSection/InvitedUser/AlarmDeal";
import M2Funded from "./Pages/Dashboard/AdminSection/InvitedUser/M2Funded";
import InstallScheduled from "./Pages/Dashboard/AdminSection/InvitedUser/InstallScheduled";
import InPermitting from "./Pages/Dashboard/AdminSection/InvitedUser/InPermitting";
import UpdateSunova from "./Pages/Dashboard/AdminSection/Sunova/UpdateSunova";
import ViewSunova from "./Pages/Dashboard/Sunova/ViewSunova";
import AllSunova from "./Pages/Dashboard/AdminSection/Sunova/AllSunova";
import PublishSunova from "./Pages/Dashboard/AdminSection/Sunova/PublishSunova";
import Planner from "./Pages/Dashboard/Planner/Planner";
import PublishFundingPrep from "./Pages/Dashboard/AdminSection/FundingPrep/PublishFundingPrep";
import AllFundingPrep from "./Pages/Dashboard/AdminSection/FundingPrep/AllFundingPrep";
import ViewFundingPrep from "./Pages/Dashboard/FundingPrep/ViewFundingPrep";
import UpdateFundingPrep from "./Pages/Dashboard/AdminSection/FundingPrep/UpdateFundingPrep";
import PublishFundingBoard from "./Pages/Dashboard/AdminSection/FundingBoard/PublishFundingBoard";
import AllFundingBoard from "./Pages/Dashboard/AdminSection/FundingBoard/AllFundingBoard";
import ViewFundingBoard from "./Pages/Dashboard/FundingBoard/FundingBoard";
import UpdateFundingBoard from "./Pages/Dashboard/AdminSection/FundingBoard/UpdateFundingBoard";
import PublishL3Calculator from "./Pages/Dashboard/AdminSection/L3Calculator/PublishL3Calculator";
import AllL3Calculator from "./Pages/Dashboard/AdminSection/L3Calculator/AllL3Calculator";
import ViewL3Calculator from "./Pages/Dashboard/L3Calculator/ViewL3Calculator";
import UpdateL3Calculator from "./Pages/Dashboard/AdminSection/L3Calculator/UpdateL3Calculator";
import PublishL2Calculator from "./Pages/Dashboard/AdminSection/L2Calculator/PublishL2Calculator";
import AllL2Calculator from "./Pages/Dashboard/AdminSection/L2Calculator/AllL2Calculator";
import ViewL2Calculator from "./Pages/Dashboard/L2Calculator/ViewL2Calculator";
import UpdateL2Calculator from "./Pages/Dashboard/AdminSection/L2Calculator/UpdateL2Calculator";
import PublishTranzactCard from "./Pages/Dashboard/AdminSection/TranzactCard/PublishTranzactCard";
import AllTranzactCard from "./Pages/Dashboard/AdminSection/TranzactCard/AllTranzactCard";
import ViewTranzactCard from "./Pages/Dashboard/ViewTranzactCard/ViewTranzactCard";
import UpdateTranzactCard from "./Pages/Dashboard/AdminSection/TranzactCard/UpdateTranzactCard";
import SuccessfullSales from "./Pages/Dashboard/AdminSection/AllUsers/SuccessfullSales";
function App() {
	const [data, info] = useData();
	const url = window.location.pathname;
	return (
		<div className='App' style={{ backgroundImage: `url(/bg-img.jpg)` }}>
			{data ? (
				<>
					{document.documentElement.style.setProperty(
						"--color1st",
						`${info?.color1st}`,
					)}
					{data?._id ? (
						<>
							{data?.status === "Active" ? (
								<APIContextProvider>
									<BrowserRouter>
										<Routes>
											<Route path='*' element={<NotFound />} />
											<Route path='/test' element={<Test />} />
											{url === "/" && (
												<Route path='/' element={<HomepageMain url={`/`} />} />
											)}
											{url.includes("/@") && (
												<Route
													path={`/@${data?.url}`}
													element={<HomepageMain url={`/@${data?.url}`} />}
												/>
											)}

											{!url.includes("/@") ? (
												<Route path='/meeting' element={<Meeting />} />
											) : (
												<Route
													path={`/@${data?.url}/meeting`}
													element={<Meeting />}
												/>
											)}
											{!url.includes("/@") ? (
												<Route path='/schedule' element={<Schedule />} />
											) : (
												<Route
													path={`/@${data?.url}/schedule`}
													element={<Schedule />}
												/>
											)}
											{!url.includes("/@") ? (
												<Route
													path='/jointeam'
													element={
														<RequireAuth>
															<TeamInvite />
														</RequireAuth>
													}
												/>
											) : (
												<Route
													path={`/@${data?.url}/jointeam`}
													element={
														<RequireAuth>
															<TeamInvite />
														</RequireAuth>
													}
												/>
											)}

											{!url.includes("/@") ? (
												<Route path='/login' element={<Login />} />
											) : (
												<Route
													path={`/@${data?.url}/login`}
													element={<Login />}
												/>
											)}
											<Route
												exact
												path='/dashboard'
												element={
													<RequireAuth userInfo={data}>
														<Dashboard />
													</RequireAuth>
												}>
												<Route path='/dashboard' element={<MyProfile />} />
												<Route
													path='/dashboard/bannerphoto'
													element={
														<RequireAuthAdmin>
															<BannerPhoto />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/profilephoto'
													element={<ProfilePhoto />}
												/>
												<Route
													path='/dashboard/scheduleByAgent'
													element={<ScheduleDashboard />}
												/>
												<Route
													path='/dashboard/AgentsPayments'
													element={<AgentsPayments />}
												/>
												<Route
													path='/dashboard/calander'
													element={<Calander />}
												/>
												<Route
													path='/dashboard/SuccessfullSales'
													element={<SuccessfullSales />}
												/>
												<Route
													path='/dashboard/newuseremail'
													element={<NewUserEmail />}
												/>
												<Route
													path='/dashboard/BlockedScheduleDate'
													element={<BlockedScheduleDate />}
												/>
												<Route
													path='/dashboard/meetings'
													element={<AllMeetings />}
												/>
												<Route
													path='/dashboard/RequestTrainingCall'
													element={<RequestTrainingCall />}
												/>
												<Route
													path='/dashboard/RequestTrainingCallList'
													element={<RequestTrainingCallList />}
												/>
												<Route
													path='/dashboard/meetings'
													element={<AllMeetings />}
												/>
												<Route
													path='/dashboard/meetingSucceed'
													element={<SuccessMeeting />}
												/>
												{/* ////////////// test route ////////// */}
												<Route
													path='/dashboard/TeamInfo'
													element={<TeamInfo />}
												/>
												<Route
													path='/dashboard/TeamInfo/:userName'
													element={<FilteredOpp />}
												/>
												<Route
													path='/dashboard/TeamManage'
													element={
														<RequireAuthAdmin>
															<TeamManage />
														</RequireAuthAdmin>
													}
												/>

												{/* ////////////// test route ////////// */}
												<Route
													path='/dashboard/allusers'
													element={
														<RequireAuthAdmin>
															<AllUsers />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/allusers/:unReadChat'
													element={
														<RequireAuthAdmin>
															<AllUsers />
														</RequireAuthAdmin>
													}
												/>
												<Route path='/dashboard/faq' element={<FAQ />} />
												<Route
													path='/dashboard/inviteuser'
													element={<InviteUser />}
												/>

												<Route
													path='/dashboard/addpaymentdetails'
													element={<AddPaymentDetails />}
												/>
												<Route
													path='/dashboard/invitedusers'
													element={<InvitedUser />}
												/>
												<Route
													path='/dashboard/DesignStarted'
													element={<DesignStarted />}
												/>
												<Route
													path='/dashboard/AlarmDeal'
													element={<AlarmDeal />}
												/>
												<Route
													path='/dashboard/M2Funded'
													element={<M2Funded />}
												/>
												<Route
													path='/dashboard/InstallScheduled'
													element={<InstallScheduled />}
												/>
												<Route
													path='/dashboard/InPermitting'
													element={<InPermitting />}
												/>
												<Route
													path='/dashboard/PostSiteSurvey'
													element={<PostSiteSurvey />}
												/>
												<Route path='/dashboard/DNQ' element={<DNQ />} />
												<Route
													path='/dashboard/InProcess'
													element={<InProcess />}
												/>
												<Route
													path='/dashboard/PreSiteSurvey'
													element={<PreSiteSurvey />}
												/>
												<Route
													path='/dashboard/AgentFollowup'
													element={<AgentFollowup />}
												/>
												<Route
													path='/dashboard/Canceled'
													element={<Canceled />}
												/>
												<Route
													path='/dashboard/ManagerFollowup'
													element={<ManagerFollowup />}
												/>
												<Route
													path='/dashboard/PhoneRoomFollowup'
													element={<PhoneRoomFollowup />}
												/>
												<Route
													path='/dashboard/successfullinvites'
													element={<SuccessInvite />}
												/>
												<Route
													path='/dashboard/headline'
													element={<Headline />}
												/>
												<Route
													path='/dashboard/allnewsletter'
													element={
														<RequireAuthAdmin>
															<AllSentNewsletter />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/ScheduleOrJoinDetails'
													element={
														<RequireAuthAdmin>
															<ScheduleOrJoinDetails />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/buttonslink'
													element={<ButtonsLink />}
												/>
												<Route
													path='/dashboard/manageproducts'
													element={
														<RequireAuthAdmin>
															<ManageProducts />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/manageproducts/:id'
													element={
														<RequireAuthAdmin>
															<ManageQuestions />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/colorchange'
													element={
														<RequireAuthAdmin>
															<ColorChange />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/details'
													element={<Details />}
												/>
												<Route
													path='/dashboard/viewnews'
													element={<ViewNews />}
												/>
												<Route
													path='/dashboard/publishnews'
													element={
														<RequireAuthAdmin>
															<PublishNews />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/newsletter'
													element={
														<RequireAuthAdmin>
															<NewsLetter />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/galleryphoto'
													element={
														<RequireAuthAdmin>
															<Galleryphoto />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/newuserwelcome'
													element={
														<RequireAuthAdmin>
															<NewUserWelcome />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/bg'
													element={
														<RequireAuthAdmin>
															<Bg />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path='/dashboard/managefaq'
													element={
														<RequireAuthAdmin>
															<ManageFAQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path='/dashboard/allreviews'
													element={<AllReviews />}
												/>
												<Route
													path='/dashboard/MemoryJoggerList'
													element={<MemoryJoggerList />}
												/>
												<Route
													path='/dashboard/bookAppoinment'
													element={<Schedule />}
												/>
												<Route
													path='/dashboard/allemails'
													element={<AllMails />}
												/>
												<Route
													path='/dashboard/bookschedules'
													element={<ScheduleBook />}
												/>
												<Route
													path='/dashboard/manageterms'
													element={
														<RequireAuthAdmin>
															<TermManage />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allnews"}
													element={
														<RequireAuthAdmin>
															<AllNews />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allnews/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateNews />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addtutorial"}
													element={
														<RequireAuthAdmin>
															<PublishTutorial />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/alltutorial"}
													element={
														<RequireAuthAdmin>
															<AllTutorial />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/tutorials"}
													element={<ViewTutorial />}
												/>
												<Route
													path={"/dashboard/alltutorial/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateTutorial />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addtraining"}
													element={
														<RequireAuthAdmin>
															<PublishTraining />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/alltraining"}
													element={
														<RequireAuthAdmin>
															<AllTraining />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/trainings"}
													element={<ViewTraining />}
												/>
												<Route
													path={"/dashboard/alltraining/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateTraining />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addCanvass"}
													element={
														<RequireAuthAdmin>
															<PublishCanvass />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allCanvass"}
													element={
														<RequireAuthAdmin>
															<AllCanvass />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Canvass"}
													element={<ViewCanvass />}
												/>
												<Route
													path={"/dashboard/allCanvass/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateCanvass />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addTranzactCard"}
													element={
														<RequireAuthAdmin>
															<PublishTranzactCard />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allTranzactCard"}
													element={
														<RequireAuthAdmin>
															<AllTranzactCard />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/TranzactCards"}
													element={<ViewTranzactCard />}
												/>
												<Route
													path={"/dashboard/allTranzactCard/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateTranzactCard />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addRPS"}
													element={
														<RequireAuthAdmin>
															<PublishRPS />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allRPS"}
													element={
														<RequireAuthAdmin>
															<AllRPS />
														</RequireAuthAdmin>
													}
												/>
												<Route path={"/dashboard/RPS"} element={<ViewRPS />} />
												<Route
													path={"/dashboard/allRPS/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateRPS />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addADTProgram"}
													element={
														<RequireAuthAdmin>
															<PublishADTProgram />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allADTProgram"}
													element={
														<RequireAuthAdmin>
															<AllADTProgram />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Charts"}
													element={<Charts />}
												/>
												<Route
													path={"/dashboard/ADTPrograms"}
													element={<ViewADTProgram />}
												/>
												<Route
													path={"/dashboard/Planner"}
													element={<Planner />}
												/>
												<Route
													path={"/dashboard/allADTProgram/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateADTProgram />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path={"/dashboard/addGoalSettingWorkshop"}
													element={
														<RequireAuthAdmin>
															<PublishGoalSettingWorkshop />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allGoalSettingWorkshop"}
													element={
														<RequireAuthAdmin>
															<AllGoalSettingWorkshop />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/GoalSettingWorkshops"}
													element={<ViewGoalSettingWorkshop />}
												/>
												<Route
													path={"/dashboard/allGoalSettingWorkshop/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateGoalSettingWorkshop />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path={"/dashboard/addPodcast"}
													element={
														<RequireAuthAdmin>
															<PublishPodcast />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allPodcast"}
													element={
														<RequireAuthAdmin>
															<AllPodcast />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Podcasts"}
													element={<ViewPodcast />}
												/>
												<Route
													path={"/dashboard/allPodcast/:id"}
													element={
														<RequireAuthAdmin>
															<UpdatePodcast />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addSunova"}
													element={
														<RequireAuthAdmin>
															<PublishSunova />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allSunova"}
													element={
														<RequireAuthAdmin>
															<AllSunova />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Sunovas"}
													element={<ViewSunova />}
												/>
												<Route
													path={"/dashboard/allSunova/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateSunova />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addFundingBoard"}
													element={
														<RequireAuthAdmin>
															<PublishFundingBoard />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allFundingBoard"}
													element={
														<RequireAuthAdmin>
															<AllFundingBoard />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/FundingBoards"}
													element={<ViewFundingBoard />}
												/>
												<Route
													path={"/dashboard/allFundingBoard/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateFundingBoard />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addFundingPrep"}
													element={
														<RequireAuthAdmin>
															<PublishFundingPrep />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allFundingPrep"}
													element={
														<RequireAuthAdmin>
															<AllFundingPrep />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/FundingPreps"}
													element={<ViewFundingPrep />}
												/>
												<Route
													path={"/dashboard/allFundingPrep/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateFundingPrep />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addL3Calculator"}
													element={
														<RequireAuthAdmin>
															<PublishL3Calculator />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allL3Calculator"}
													element={
														<RequireAuthAdmin>
															<AllL3Calculator />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/L3Calculators"}
													element={<ViewL3Calculator />}
												/>
												<Route
													path={"/dashboard/allL3Calculator/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateL3Calculator />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addL2Calculator"}
													element={
														<RequireAuthAdmin>
															<PublishL2Calculator />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allL2Calculator"}
													element={
														<RequireAuthAdmin>
															<AllL2Calculator />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/L2Calculators"}
													element={<ViewL2Calculator />}
												/>
												<Route
													path={"/dashboard/allL2Calculator/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateL2Calculator />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path={"/dashboard/addSpinDoctoring"}
													element={
														<RequireAuthAdmin>
															<PublishSpinDoctoring />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allSpinDoctoring"}
													element={
														<RequireAuthAdmin>
															<AllSpinDoctoring />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/SpinDoctorings"}
													element={<ViewSpinDoctoring />}
												/>
												<Route
													path={"/dashboard/allSpinDoctoring/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateSpinDoctoring />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path={"/dashboard/addTheMasterOfSuccessVideos"}
													element={
														<RequireAuthAdmin>
															<PublishTheMasterOfSuccessVideos />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allTheMasterOfSuccessVideos"}
													element={
														<RequireAuthAdmin>
															<AllTheMasterOfSuccessVideos />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/TheMasterOfSuccessVideoss"}
													element={<ViewTheMasterOfSuccessVideos />}
												/>
												<Route
													path={"/dashboard/allTheMasterOfSuccessVideos/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateTheMasterOfSuccessVideos />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path={"/dashboard/addTheMasterSuccessPlanner"}
													element={
														<RequireAuthAdmin>
															<PublishTheMasterSuccessPlanner />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allTheMasterSuccessPlanner"}
													element={
														<RequireAuthAdmin>
															<AllTheMasterSuccessPlanner />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/TheMasterSuccessPlanners"}
													element={<ViewTheMasterSuccessPlanner />}
												/>
												<Route
													path={"/dashboard/allTheMasterSuccessPlanner/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateTheMasterSuccessPlanner />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path={"/dashboard/addWinningPresentations"}
													element={
														<RequireAuthAdmin>
															<PublishWinningPresentations />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allWinningPresentations"}
													element={
														<RequireAuthAdmin>
															<AllWinningPresentations />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/WinningPresentationss"}
													element={<ViewWinningPresentations />}
												/>
												<Route
													path={"/dashboard/allWinningPresentations/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateWinningPresentations />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path={"/dashboard/allReferrels"}
													element={
														<RequireAuthAdmin>
															<RefferelsList />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/RefferelMessgae"}
													element={
														<RequireAuthAdmin>
															<RefferelMessgae />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addLevel1Program"}
													element={
														<RequireAuthAdmin>
															<PublishLevel1 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allLevel1Program"}
													element={
														<RequireAuthAdmin>
															<AllLevel1 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Level1Programs"}
													element={<ViewLevel1 />}
												/>
												<Route
													path={"/dashboard/allLevel1Program/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateLevel1 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addLevel2Program"}
													element={
														<RequireAuthAdmin>
															<PublishLevel2 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allLevel2Program"}
													element={
														<RequireAuthAdmin>
															<AllLevel2 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Level2Programs"}
													element={<ViewLevel2 />}
												/>
												<Route
													path={"/dashboard/allLevel2Program/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateLevel2 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addLevel3Program"}
													element={
														<RequireAuthAdmin>
															<PublishLevel3 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allLevel3Program"}
													element={
														<RequireAuthAdmin>
															<AllLevel3 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/Level3Programs"}
													element={<ViewLevel3 />}
												/>
												<Route
													path={"/dashboard/allLevel3Program/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateLevel3 />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addLevel2MCQ"}
													element={
														<RequireAuthAdmin>
															<PublishLevel2MCQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allLevel2MCQ"}
													element={
														<RequireAuthAdmin>
															<AllLevel2MCQ />
														</RequireAuthAdmin>
													}
												/>
												{/* 	<Route
													path={"/dashboard/Level2MCQ"}
													element={<ViewLevel2MCQ />}
												/> */}
												<Route
													path={"/dashboard/allLevel2MCQ/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateLevel2MCQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addLevel3MCQ"}
													element={
														<RequireAuthAdmin>
															<PublishLevel3MCQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allLevel3MCQ"}
													element={
														<RequireAuthAdmin>
															<AllLevel3MCQ />
														</RequireAuthAdmin>
													}
												/>
												{/* 	<Route
													path={"/dashboard/Level3MCQ"}
													element={<ViewLevel3MCQ />}
												/> */}
												<Route
													path={"/dashboard/allLevel3MCQ/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateLevel3MCQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addLevel1MCQ"}
													element={
														<RequireAuthAdmin>
															<PublishLevel1MCQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allLevel1MCQ"}
													element={
														<RequireAuthAdmin>
															<AllLevel1MCQ />
														</RequireAuthAdmin>
													}
												/>
												{/* 	<Route
													path={"/dashboard/Level1MCQ"}
													element={<ViewLevel1MCQ />}
												/> */}
												<Route
													path={"/dashboard/allLevel1MCQ/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateLevel1MCQ />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/addADTMCQ"}
													element={
														<RequireAuthAdmin>
															<PublishAdtMcq />
														</RequireAuthAdmin>
													}
												/>
												{/* <Route
													path={"/dashboard/allADTMCQ"}
													element={
														<RequireAuthAdmin>
															<AllAdtMcq />
														</RequireAuthAdmin>
													}
												/> */}
												{/* 	<Route
													path={"/dashboard/ADTMCQ"}
													element={<ViewLevel1MCQ />}
												/> */}
												{/* <Route
													path={"/dashboard/allADTMCQ/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateAdtMcq />
														</RequireAuthAdmin>
													}
												/> */}

												<Route
													path={"/dashboard/addpost"}
													element={
														<RequireAuthAdmin>
															<SocialMediaPost />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allpost"}
													element={
														<RequireAuthAdmin>
															<AllSocialMediaPost />
														</RequireAuthAdmin>
													}
												/>
												<Route
													path={"/dashboard/allposts"}
													element={<ViewSocialMediaPost />}
												/>
												<Route
													path={"/dashboard/allpost/:id"}
													element={
														<RequireAuthAdmin>
															<UpdateSocialMediaPost />
														</RequireAuthAdmin>
													}
												/>

												<Route
													path='/dashboard/managevideo'
													element={
														<RequireAuthAdmin>
															<AddVideo />
														</RequireAuthAdmin>
													}
												/>
											</Route>
										</Routes>
									</BrowserRouter>
								</APIContextProvider>
							) : (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
										minHeight: "100vh",
									}}>
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h3'
										component='div'>
										OPPS!
									</Typography>
									<br />
									<Typography
										className='textColor'
										sx={{ fontWeight: "bold" }}
										variant='h5'
										component='div'>
										This card is not available at the moment.
									</Typography>
								</div>
							)}
						</>
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								minHeight: "100vh",
							}}>
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h3'
								component='div'>
								OPPS!
							</Typography>
							<br />
							<Typography
								className='textColor'
								sx={{ fontWeight: "bold" }}
								variant='h5'
								component='div'>
								{data}
							</Typography>
						</div>
					)}
				</>
			) : (
				<div
					style={{
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<CardMedia
						image='/loading.png'
						alt='loading logo'
						className='loading-logo'
						sx={{
							width: 170,
							height: 170,
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default App;
