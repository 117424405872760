import React, { useEffect, useState } from "react";
import {
	Button,
	Grid,
	TextField,
	Typography,
	CircularProgress,
	Backdrop,
	Container,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Modal,
	Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "95%",
	maxWidth: 1200,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	maxHeight: "90vh",
	overflowY: "auto",
	borderRadius: "8px",
};

const buttonStyle = {
	textTransform: "none",
	borderRadius: "8px",
	fontWeight: 600,
};

const DynamicMcq = ({ type }) => {
	const [mode, setMode] = useState("list");
	const [submitting, setSubmitting] = useState(false);
	const [mcqData, setMcqData] = useState();
	const [selectedId, setSelectedId] = useState(null);
	const [newList, setNewList] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const { register, handleSubmit, reset, setValue } = useForm({
		defaultValues: {
			messageForCorrectAnswer: "Correct answer. Good job.",
			messageForIncorrectAnswer: "Incorrect answer. Please try again.",
		},
	});

	const apiEndpoint = `https://americasolarpros.herokuapp.com/mcq`;

	const fetchMCQs = () => {
		fetch(`${apiEndpoint}/${type}`)
			.then((res) => res.json())
			.then((data) => {
				setMcqData(data);
				setNewList(data);
			});
	};

	useEffect(() => {
		fetchMCQs();
	}, [type, apiEndpoint]);

	const handleOpenModal = async (id = null) => {
		setOpenModal(true);
		if (id) {
			setMode("edit");
			setSelectedId(id);
			setIsEditing(true);
			try {
				const res = await axios.get(`${apiEndpoint}/mcqByID/${id}`);
				const data = res.data;
				setValue("question", data?.question);
				setValue("answer1", data?.answers?.[0]);
				setValue("answer2", data?.answers?.[1]);
				setValue("answer3", data?.answers?.[2]);
				setValue("answer4", data?.answers?.[3]);
				setValue("correctAnswer", data?.correctAnswer);
				setValue("messageForCorrectAnswer", data?.messageForCorrectAnswer);
				setValue("messageForIncorrectAnswer", data?.messageForIncorrectAnswer);
				setValue("explanation", data?.explanation);
			} catch (error) {
				console.error("Error loading MCQ:", error);
				Swal.fire({
					icon: "error",
					title: "Error loading MCQ",
					text: "Please try again",
				});
				setMode("list"); // Go back to list on error
			} finally {
				setIsEditing(false); // Stop loading
			}
		} else {
			setMode("add");
			reset({
				messageForCorrectAnswer: "Correct answer. Good job.",
				messageForIncorrectAnswer: "Incorrect answer. Please try again.",
			});
		}
	};

	const handleDrop = (droppedItem) => {
		if (!droppedItem.destination) return;
		var updatedList = [...newList];
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		setNewList(updatedList);
	};

	const onSubmit = async (formData) => {
		const data = {
			question: formData.question,
			questionType: "text",
			answers: [
				formData.answer1,
				formData.answer2,
				formData.answer3,
				formData.answer4,
			],
			correctAnswer: formData.correctAnswer,
			messageForCorrectAnswer: formData.messageForCorrectAnswer,
			messageForIncorrectAnswer: formData.messageForIncorrectAnswer,
			explanation: formData.explanation,
			submitTime: new Date().toLocaleString("en-US", {
				timeZone: "America/Los_Angeles",
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				timeZoneName: "short",
			}),
			type: type,
		};

		setSubmitting(true);
		try {
			const request =
				mode === "edit"
					? axios.put(`${apiEndpoint}/${selectedId}`, data)
					: axios.post(apiEndpoint, data);

			await request;
			setSubmitting(false);
			await Swal.fire({
				icon: "success",
				title: `Successfully ${mode === "edit" ? "Updated" : "Posted"}`,
				showConfirmButton: true,
				timer: 1500,
			});
			setMode("list");
			reset();
			fetchMCQs(); // Refetch after submit
		} catch (error) {
			setSubmitting(false);
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Failed to save MCQ. Please try again.",
			});
		}
	};

	const handleDelete = (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				setSubmitting(true);
				axios
					.delete(`${apiEndpoint}/${id}`)
					.then(() => {
						setSubmitting(false);
						Swal.fire("Deleted!", "That MCQ has been deleted.", "success");
						fetchMCQs(); // Refetch after delete
					})
					.catch(console.error);
			}
		});
	};

	const handleSort = () => {
		setSubmitting(true);
		axios
			.put(`${apiEndpoint}/sort/all`, newList)
			.then(() => {
				setSubmitting(false);
				Swal.fire({
					icon: "success",
					title: "Successfully Sorted",
					showConfirmButton: true,
					timer: 1500,
				});
			})
			.catch(console.error);
	};

	const MCQForm = () => (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						label='Question'
						InputLabelProps={{ shrink: true }}
						{...register("question", { required: true })}
					/>
				</Grid>
				{[1, 2, 3, 4].map((num) => (
					<Grid item xs={12} sm={6} key={num}>
						<TextField
							required
							fullWidth
							label={`Choice ${num}`}
							InputLabelProps={{ shrink: true }}
							{...register(`answer${num}`, { required: true })}
						/>
					</Grid>
				))}
				<Grid item xs={12} sm={6}>
					<TextField
						required
						fullWidth
						type='number'
						label='Correct Answer'
						placeholder='1, 2, 3, or 4'
						InputLabelProps={{ shrink: true }}
						{...register("correctAnswer", { required: true })}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						label='Message For Correct Answer'
						InputLabelProps={{ shrink: true }}
						{...register("messageForCorrectAnswer", { required: true })}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						label='Message For Incorrect Answer'
						InputLabelProps={{ shrink: true }}
						{...register("messageForIncorrectAnswer", { required: true })}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						label='Explanation'
						InputLabelProps={{ shrink: true }}
						{...register("explanation", { required: true })}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ display: "flex", gap: 2 }}>
						<Button
							type='button'
							variant='outlined'
							onClick={() => setMode("list")}
							fullWidth
							sx={buttonStyle}>
							Back to List
						</Button>
						<Button
							type='submit'
							variant='contained'
							fullWidth
							sx={buttonStyle}>
							{mode === "edit" ? "Update" : "Submit"}
						</Button>
					</Box>
				</Grid>
			</Grid>
		</form>
	);

	const MCQTable = () => (
		<Paper
			sx={{
				overflow: "auto",
				maxHeight: "60vh",
				width: "100%",
				borderRadius: "8px",
				boxShadow: 3,
			}}>
			<Table size='small' stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell align='center' colSpan={4}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									py: 2,
								}}>
								<Typography variant='h5' sx={{ fontWeight: 600 }}>
									{type} MCQ List
								</Typography>
								<Box>
									<Button
										variant='contained'
										color='secondary'
										onClick={() => {
											setMode("add");
											reset();
										}}
										sx={{ ...buttonStyle, mr: 1 }}>
										Add New MCQ
									</Button>
									{mcqData !== newList && (
										<Button
											variant='contained'
											color='error'
											onClick={handleSort}
											sx={buttonStyle}>
											Save Sort Order
										</Button>
									)}
								</Box>
							</Box>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell sx={{ fontWeight: "bold" }}>Question</TableCell>
						<TableCell sx={{ fontWeight: "bold" }}>Posted Time</TableCell>
						<TableCell align='center' sx={{ fontWeight: "bold" }}>
							Actions
						</TableCell>
					</TableRow>
				</TableHead>
				<DragDropContext onDragEnd={handleDrop}>
					<Droppable droppableId='mcq-list'>
						{(provided) => (
							<TableBody {...provided.droppableProps} ref={provided.innerRef}>
								{newList?.map((mcq, index) => (
									<Draggable key={mcq._id} draggableId={mcq._id} index={index}>
										{(provided) => (
											<TableRow
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}>
												<TableCell>{mcq.question}</TableCell>
												<TableCell>{mcq.submitTime}</TableCell>
												<TableCell align='center'>
													<Button
														onClick={() => handleOpenModal(mcq._id)}
														sx={{ m: 1 }}>
														<EditIcon />
													</Button>
													<Button
														onClick={() => handleDelete(mcq._id)}
														sx={{ m: 1 }}>
														<DeleteIcon />
													</Button>
												</TableCell>
											</TableRow>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</TableBody>
						)}
					</Droppable>
				</DragDropContext>
			</Table>
		</Paper>
	);

	return (
		<>
			<Button
				variant='contained'
				onClick={() => setOpenModal(true)}
				sx={{ ...buttonStyle, m: 2 }}>
				Manage {type} MCQs
			</Button>

			<Modal
				open={openModal}
				onClose={() => {
					setOpenModal(false);
					setMode("list");
					setSelectedId(null);
					reset();
				}}
				aria-labelledby='mcq-modal-title'>
				<Box sx={modalStyle}>
					{mode === "list" ? (
						<MCQTable />
					) : (
						<Paper sx={{ p: 3, borderRadius: "8px", position: "relative" }}>
							<Typography variant='h5' gutterBottom sx={{ fontWeight: 600 }}>
								{mode === "edit" ? `Edit ${type} MCQ` : `Add New ${type} MCQ`}
							</Typography>
							{isEditing ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										minHeight: "300px",
									}}>
									<CircularProgress />
								</Box>
							) : (
								<MCQForm />
							)}
						</Paper>
					)}
				</Box>
			</Modal>

			<Backdrop open={submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</>
	);
};

export default DynamicMcq;
