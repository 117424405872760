import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	Typography,
} from "@mui/material";
import PdfLibrary from "../PdfLibrary/PdfLibrary";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DynamicMcq from "../AdminSection/DynamicMcq/DynamicMcq";
import McqSubmit from "../AdminSection/DynamicMcq/McqSubmit";
import { useAPI } from "../../../apiContext";

const ViewFundingBoard = () => {
	const [FundingBoard, setFundingBoard] = useState();
	useEffect(() => {
		fetch(`https://americasolarpros.herokuapp.com/FundingBoard`)
			.then((res) => res.json())
			.then((data) => {
				setFundingBoard(data);
			});
	}, []);
	const [videoNo, setVideoNo] = useState(0);
	const [open, setOpen] = useState(false);
	const { databyEmail } = useAPI();
	return (
		<Box sx={{ px: 2 }}>
			<Typography
				className='textColor'
				sx={{ fontWeight: "bold" }}
				variant='h4'
				gutterBottom>
				Funding Board
			</Typography>
			{databyEmail?.role === "Admin" && <DynamicMcq type='FundingBoard' />}
			<Grid container spacing={2} sx={{ justifyContent: "center" }}>
				<Grid item md={7} xs={12}>
					<ReactPlayer
						className='borderColor react-player'
						style={{ borderRadius: 11, backgroundColor: "black" }}
						width='100%'
						height='100%'
						url={FundingBoard?.[videoNo]?.video}
						controls
					/>
					<Typography
						className='textColor'
						sx={{ fontWeight: "bold", py: 0.5 }}
						variant='h6'>
						{FundingBoard?.[videoNo]?.title}
					</Typography>
					<Typography
						className='textColor'
						variant='body2'
						sx={{ whiteSpace: "pre-line" }}>
						{FundingBoard?.[videoNo]?.description}
					</Typography>
					<Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
						<Button
							onClick={() => setVideoNo(videoNo === 0 ? videoNo : videoNo - 1)}
							variant='contained'
							className='button border'
							sx={{
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}>
							Previous
						</Button>
						<Button
							onClick={() => setOpen(true)}
							variant='contained'
							className='button border'
							sx={{
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}>
							<PictureAsPdfIcon sx={{ mr: 1 }} /> PDF Library
						</Button>
						<Button
							onClick={() =>
								setVideoNo(
									FundingBoard?.length - 1 === videoNo ? videoNo : videoNo + 1,
								)
							}
							variant='contained'
							className='button border'
							sx={{
								mb: 2,
								px: 3,
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
							}}>
							Next
						</Button>
					</Box>
				</Grid>
				<Grid item md={5} xs={12}>
					<Box
						sx={{
							maxHeight: "80vh",
							overflowY: "scroll",
							textAlign: "left",
						}}>
						{FundingBoard?.map((n, i) => (
							<Typography
								onClick={() => setVideoNo(i)}
								className={`textColor ${videoNo === i && "boldVideoTitle"}`}
								sx={{ p: 1, border: "1px solid", cursor: "pointer" }}>
								{n?.title}
							</Typography>
						))}
					</Box>
				</Grid>
			</Grid>
			<McqSubmit type='FundingBoard' />
			{open && (
				<PdfLibrary open={open} setOpen={setOpen} category={"Funding-Board"} />
			)}
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!FundingBoard}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default ViewFundingBoard;
