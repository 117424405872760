import {
	Box,
	Button,
	Checkbox,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Backdrop,
	Fade,
	Paper,
	CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import { useAPI } from "../../../apiContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "../../../Pagination/Pagination";

const Planner = () => {
	const [allPlanners, setAllPlanners] = useState(false);
	const [flterDate, setFilterDate] = useState(null);
	const { databyEmail } = useAPI();
	const [submitting, setSubmitting] = useState(false);
	const [tasks, setTasks] = useState([
		{
			id: Math.floor(Math.random() * 90000) + 10000,
			what: "",
			when: "",
			why: "",
			how: "",
			who: "",
			completed: false,
			reschedule: false,
			trash: false,
		},
	]);
	console.log("🚀 ~ file: Planner.js:46 ~ Planner ~ tasks:", tasks);

	const [tempArray, setTempArray] = useState([
		{
			id: Math.floor(Math.random() * 90000) + 10000,
			what: "",
			when: "",
			why: "",
			how: "",
			who: "",
			completed: false,
			reschedule: false,
			trash: false,
		},
	]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalItems, setTotalItems] = useState(0);

	// Function to handle page change
	const handlePageChange = (newPage, newItemsPerPage) => {
		setCurrentPage(newPage);
		if (newItemsPerPage !== undefined) {
			setItemsPerPage(newItemsPerPage);
		}
	};
	useEffect(() => {
		const fetchSortedTasks = async (user) => {
			try {
				const response = await axios.get(
					`https://americasolarpros.herokuapp.com/${
						allPlanners === false
							? `sortedTasks/${user}?page=${currentPage}&perPage=${itemsPerPage}`
							: `sortedTasksAll?page=${currentPage}&perPage=${itemsPerPage}`
					}`,
				);
				setTasks(response.data.data);
				setTempArray(response.data.data);
				setTotalItems(response.data.pagination.total);
			} catch (error) {
				console.error("Error fetching tasks:", error);
			}
		};
		databyEmail?.url && fetchSortedTasks(databyEmail?.url);
	}, [allPlanners, currentPage, databyEmail, itemsPerPage, submitting]);

	const handleSaveEdit = async () => {
		try {
			const response = await axios.put(
				`https://americasolarpros.herokuapp.com/Planners`,
				{
					planner: tempArray,
					url: databyEmail?.url,
				},
			);
			console.log("Edit response:", response.data);
			setSubmitting(!submitting);
			setIsOpen(false);
		} catch (error) {
			console.error("Error editing task:", error);
		}
	};

	const [shouldSaveEdit, setShouldSaveEdit] = useState(false);

	const handleRemove = (taskId) => {
		setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
		setTempArray((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
		setShouldSaveEdit(true);
	};

	useEffect(() => {
		if (shouldSaveEdit) {
			handleSaveEdit();
			setShouldSaveEdit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldSaveEdit]);

	const handleAddTask = () => {
		const newData = {
			id: Math.floor(Math.random() * 90000) + 10000,
			what: "",
			when: "",
			why: "",
			how: "",
			who: "",
			completed: false,
			reschedule: false,
			trash: false,
		};
		setTasks((prevTasks) => [...prevTasks, newData]);
		setTempArray((prevTasks) => [...prevTasks, newData]);
	};

	const handleInputChange = (taskId, column, value) => {
		setTasks((prevTasks) => {
			const updatedTasks = prevTasks.map((task) =>
				task.id === taskId ? { ...task, [column]: value } : task,
			);
			return updatedTasks;
		});
		setTempArray((prevTasks) => {
			const updatedTasks = prevTasks.map((task) =>
				task.id === taskId ? { ...task, [column]: value } : task,
			);
			return updatedTasks;
		});
	};

	const handleCheckboxChange = (taskId, column, oldDate) => {
		setTasks((prevTasks) => {
			const updatedTasks = prevTasks.map((task) => {
				if (task.id === taskId) {
					let newColumnValue = false;
					let newRescheduleValue = false;

					if (column === "completed") {
						newColumnValue = true;
					} else if (column === "reschedule") {
						newColumnValue = true;
						newRescheduleValue = true;
					} else if (column === "trash") {
						newColumnValue = true;
					}

					return {
						...task,
						completed: newColumnValue && column === "completed",
						reschedule:
							newRescheduleValue &&
							column === "reschedule" &&
							openModal(taskId, oldDate),
						trash: newColumnValue && column === "trash",
					};
				}
				return task;
			});

			return updatedTasks;
		});

		setTempArray((prevTasks) => {
			const updatedTasks = prevTasks.map((task) => {
				if (task.id === taskId) {
					let newColumnValue = false;
					let newRescheduleValue = false;

					if (column === "completed") {
						newColumnValue = true;
					} else if (column === "reschedule") {
						newColumnValue = true;
						newRescheduleValue = true;
					} else if (column === "trash") {
						newColumnValue = true;
					}

					return {
						...task,
						completed: newColumnValue && column === "completed",
						reschedule:
							newRescheduleValue &&
							column === "reschedule" &&
							openModal(taskId, oldDate),
						trash: newColumnValue && column === "trash",
					};
				}
				return task;
			});

			return updatedTasks;
		});
	};

	const handleDateChange = (taskId, value) => {
		setTasks((prevTasks) => {
			const updatedTasks = prevTasks.map((task) =>
				task.id === taskId ? { ...task, when: value } : task,
			);
			return updatedTasks;
		});

		setTempArray((prevTasks) => {
			const updatedTasks = prevTasks.map((task) =>
				task.id === taskId ? { ...task, when: value } : task,
			);
			return updatedTasks;
		});
	};

	const [isOpen, setIsOpen] = useState(false);

	const openModal = (taskId, oldDate) => {
		setIsOpen(true);
		setDateChange((prevDateChange) => ({
			...prevDateChange,
			index: taskId,
			oldDate: oldDate,
		}));
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const [dateChange, setDateChange] = useState({
		newDate: "",
		oldDate: "",
		index: "",
	});

	const renderTableRows = () => {
		return tasks?.map((task, index) => (
			<TableRow
				key={index}
				sx={{
					"& .MuiCheckbox-root": {
						backgroundColor: "transparent !important",
					},
				}}>
				<TableCell align='center'>{index + 1}</TableCell>
				<TableCell align='center'>
					<RemoveRedEyeIcon
						onClick={() => {
							setSelectedTask(task);
						}}
					/>
				</TableCell>
				<TableCell>
					<TextField
						disabled={allPlanners === true}
						size='small'
						variant='outlined'
						fullWidth
						type='text'
						value={task.what}
						onChange={(e) =>
							handleInputChange(task?.id, "what", e.target.value)
						}
					/>
				</TableCell>
				<TableCell>
					<TextField
						disabled={allPlanners === true}
						size='small'
						variant='outlined'
						fullWidth
						type='text'
						value={task.when}
						onClick={() => openModal(task?.id, task.when)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						disabled={allPlanners === true}
						size='small'
						variant='outlined'
						fullWidth
						type='text'
						value={task.why}
						onChange={(e) => handleInputChange(task?.id, "why", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						disabled={allPlanners === true}
						size='small'
						variant='outlined'
						fullWidth
						type='text'
						value={task.how}
						onChange={(e) => handleInputChange(task?.id, "how", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						disabled={allPlanners === true}
						size='small'
						variant='outlined'
						fullWidth
						type='text'
						value={task.who}
						onChange={(e) => handleInputChange(task?.id, "who", e.target.value)}
					/>
				</TableCell>
				<TableCell align='center'>
					<Checkbox
						disabled={allPlanners === true}
						type='checkbox'
						checked={task.completed}
						onChange={() => handleCheckboxChange(task?.id, "completed")}
					/>
				</TableCell>
				<TableCell align='center'>
					<Checkbox
						disabled={allPlanners === true}
						type='checkbox'
						checked={task.reschedule}
						onChange={() => {
							handleCheckboxChange(task?.id, "reschedule", task.when);
						}}
					/>
				</TableCell>
				<TableCell align='center'>
					<Checkbox
						disabled={allPlanners === true}
						type='checkbox'
						checked={task.trash}
						onChange={() => handleCheckboxChange(task?.id, "trash")}
					/>
				</TableCell>
				<TableCell align='center'>
					{allPlanners === true ? (
						`${task?.user}`
					) : (
						<Box
							sx={{
								display: "flex",
							}}>
							<Button onClick={handleSaveEdit}>Save</Button>
							<Button onClick={() => handleRemove(task?.id)}>
								<DeleteOutlineIcon />
							</Button>
						</Box>
					)}
				</TableCell>
			</TableRow>
		));
	};
	const [selectedDate, setSelectedDate] = useState(null);
	const markedDates = tasks?.map((timeObject) => timeObject);
	const handleDateClick = (dateIn) => {
		setSelectedDate(dateIn);
		const filteredTimes = tasks?.filter((item) => {
			const date = new Date(item?.when);
			const selected = new Date(dateIn);
			return date.toDateString() === selected.toDateString();
		});

		setTasks(filteredTimes);
		setFilterDate(false);
	};

	const tileClassName = ({ date }) => {
		return markedDates?.some((markedDate) =>
			dayjs(markedDate?.when).isSame(date, "day"),
		)
			? "highlighted"
			: "blocked";
	};
	const tileContent = ({ date, view }) => {
		return (
			<span
				style={{
					fontSize: "0.9rem",
				}}>
				<br />
				{tasks?.filter((user) => dayjs(user?.when).isSame(date, "day")).length >
				0
					? `(${
							tasks?.filter((user) => dayjs(user?.when).isSame(date, "day"))
								.length
					  })`
					: null}
			</span>
		);
	};
	const [selectedTask, setSelectedTask] = useState();
	const closeSelectedTaskModal = () => {
		setSelectedTask(false);
	};

	return (
		<Box
			sx={{
				p: 2,
				"& .MuiButton-root": {
					border: "1px solid",
				},
				"& .MuiTableBody-root td:nth-child(3),.MuiTableBody-root td:nth-child(4),.MuiTableBody-root td:nth-child(5),.MuiTableBody-root td:nth-child(6),.MuiTableBody-root td:nth-child(7)":
					{
						minWidth: "190px !important",
					},
			}}>
			<Pagination
				totalItems={totalItems}
				currentPage={currentPage}
				itemsPerPage={itemsPerPage}
				onPageChange={handlePageChange}
			/>
			<Paper
				sx={{
					overflow: "auto",
					maxHeight: "85vh",
					maxWidth: "95vw",
					m: 2,
					width: "100%",
				}}>
				<Box>
					{allPlanners === false && (
						<Button variant='contained' color='primary' onClick={handleAddTask}>
							Add New Task
						</Button>
					)}
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							setTasks(tempArray);
							setFilterDate(true);
						}}>
						Filter Task
					</Button>
					{databyEmail?.role === "Admin" && (
						<Button
							variant='contained'
							color='primary'
							sx={{
								backgroundColor:
									allPlanners === false ? "green !important" : "red !important",
							}}
							onClick={() => {
								setAllPlanners(!allPlanners);
							}}>
							{allPlanners === false ? "All Tasks" : "Your Tasks"}
						</Button>
					)}
				</Box>
				<Table size='small' stickyHeader aria-label='sticky table'>
					<TableHead sx={{ th: { fontWeight: "bold" } }}>
						<TableRow>
							<TableCell align='center'>No</TableCell>
							<TableCell align='center'>View</TableCell>
							<TableCell align='center'>What</TableCell>
							<TableCell align='center'>When</TableCell>
							<TableCell align='center'>Why</TableCell>
							<TableCell align='center'>How</TableCell>
							<TableCell align='center'>Who</TableCell>
							<TableCell align='center'>Completed</TableCell>
							<TableCell align='center'>Reschedule</TableCell>
							<TableCell align='center'>Trash</TableCell>
							<TableCell align='center'>
								{allPlanners === true ? "User" : "Action"}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{renderTableRows()}</TableBody>
				</Table>
			</Paper>{" "}
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={flterDate}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={flterDate}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							background: "#fff",
							padding: "20px",
							borderRadius: "5px",
							outline: "none",
							width: {
								xs: "100%",
								md: "auto",
							},
							mx: 2,
						}}
						style={{ backgroundImage: `url(/bg-img.jpg)` }}>
						<CancelIcon
							onClick={() => {
								setTasks(tempArray);
								setFilterDate(false);
							}}
							className='textColor'
							sx={{
								position: "fixed",
								top: "5px",
								right: "5px",
								cursor: "pointer",
							}}
						/>
						<Typography
							variant='h4'
							gutterBottom
							className='textColor'
							sx={{ fontWeight: "bold" }}>
							FILTER TASKS
						</Typography>
						<Calendar
							value={selectedDate}
							onClickDay={handleDateClick}
							tileClassName={tileClassName}
							tileContent={({ date, view }) => tileContent({ date, view })}
						/>
					</Box>
				</Fade>
			</Modal>
			<Modal
				open={selectedTask}
				onClose={closeSelectedTaskModal}
				aria-labelledby='modal-title'
				aria-describedby='modal-description'>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						background: "#fff",
						padding: "20px",
						borderRadius: "5px",
						outline: "none",
						minWidth: "400px",
					}}>
					{" "}
					<CancelIcon
						onClick={() => setSelectedTask(null)}
						className='textColor'
						sx={{
							position: "fixed",
							top: "5px",
							right: "5px",
							cursor: "pointer",
						}}
					/>
					<Typography
						className='textColor'
						variant='h5'
						gutterBottom
						sx={{
							textAlign: "center",
							fontWeight: "bold",
						}}>
						More About Task
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "stretch",
						}}>
						<Box sx={{ my: 1 }}>
							<TextField
								label='What'
								disabled={allPlanners === true}
								size='small'
								variant='outlined'
								fullWidth
								type='text'
								defaultValue={selectedTask?.what}
								onChange={(e) =>
									handleInputChange(selectedTask?.id, "what", e.target.value)
								}
								multiline
								minRows={2}
							/>
						</Box>
						<Box sx={{ my: 1 }}>
							<TextField
								label='When'
								disabled={allPlanners === true}
								size='small'
								variant='outlined'
								fullWidth
								type='text'
								defaultValue={selectedTask?.when}
								onClick={() => openModal(selectedTask?.id, selectedTask?.when)}
								multiline
								minRows={2}
							/>
						</Box>
						<Box sx={{ my: 1 }}>
							<TextField
								label='Why'
								disabled={allPlanners === true}
								size='small'
								variant='outlined'
								fullWidth
								type='text'
								defaultValue={selectedTask?.why}
								onChange={(e) =>
									handleInputChange(selectedTask?.id, "why", e.target.value)
								}
								multiline
								minRows={2}
							/>
						</Box>
						<Box sx={{ my: 1 }}>
							<TextField
								label='How'
								disabled={allPlanners === true}
								size='small'
								variant='outlined'
								fullWidth
								type='text'
								defaultValue={selectedTask?.how}
								onChange={(e) =>
									handleInputChange(selectedTask?.id, "how", e.target.value)
								}
								multiline
								minRows={2}
							/>
						</Box>
						<Box sx={{ my: 1 }}>
							<TextField
								label='Who'
								disabled={allPlanners === true}
								size='small'
								variant='outlined'
								fullWidth
								type='text'
								defaultValue={selectedTask?.who}
								onChange={(e) =>
									handleInputChange(selectedTask?.id, "who", e.target.value)
								}
								multiline
								minRows={2}
							/>
						</Box>
						<Box>
							Completed :{" "}
							<Checkbox
								disabled={allPlanners === true}
								type='checkbox'
								checked={selectedTask?.completed}
								onChange={() =>
									handleCheckboxChange(selectedTask?.id, "completed")
								}
							/>
						</Box>
						<Box>
							Rescheduled :{" "}
							<Checkbox
								disabled={allPlanners === true}
								type='checkbox'
								checked={selectedTask?.reschedule}
								onChange={() => {
									handleCheckboxChange(
										selectedTask?.id,
										"reschedule",
										selectedTask?.when,
									);
								}}
							/>
						</Box>
						<Box>
							Trash :{" "}
							<Checkbox
								disabled={allPlanners === true}
								type='checkbox'
								checked={selectedTask?.trash}
								onChange={() => handleCheckboxChange(selectedTask?.id, "trash")}
							/>
						</Box>
						<Box>
							{allPlanners === true ? (
								`User : ${selectedTask?.user}`
							) : (
								<Box
									sx={{
										display: "flex",
									}}>
									<Button onClick={handleSaveEdit}>Save</Button>
									<Button onClick={() => handleRemove(selectedTask?.id)}>
										<DeleteOutlineIcon />
									</Button>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Modal>
			<Modal
				open={isOpen}
				onClose={closeModal}
				aria-labelledby='modal-title'
				aria-describedby='modal-description'>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						background: "#fff",
						padding: "20px",
						borderRadius: "5px",
						outline: "none",
						maxWidth: "400px",
					}}>
					<Typography
						className='textColor'
						variant='h5'
						gutterBottom
						sx={{
							textAlign: "center",
							fontWeight: "bold",
						}}>
						Change Date Time
					</Typography>
					<div>
						<TextField
							type='datetime-local'
							variant='outlined'
							fullWidth
							value={dateChange?.newDate}
							onChange={(e) =>
								setDateChange((prevDateChange) => ({
									...prevDateChange,
									newDate: e.target.value,
								}))
							}
						/>
					</div>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-around",
							marginTop: "20px",
						}}>
						<Button
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							onClick={() => {
								handleDateChange(dateChange.index, dateChange.newDate);
								closeModal();
								setDateChange({});
							}}>
							Change Date
						</Button>

						<Button
							className='button border'
							sx={{
								fontWeight: "bold",
								border: "2px solid",
								backgroundColor: "transparent",
								borderRadius: "25px",
								m: 0.5,
							}}
							onClick={closeModal}>
							Close
						</Button>
					</Box>
				</Box>
			</Modal>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!tempArray}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Box>
	);
};

export default Planner;
